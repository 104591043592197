import { Action } from "redux";
import { FileUploadResponse } from "../../server/services/fileService";
import { Timeline } from "../../server/utils/schedule/generateTimeline";
import {
  CompanyData,
  OrderData,
  SubscriptionData,
} from "@shared/types/Upsales.types";
import {
  CacheLevel,
  FileUploadScope,
  ICarousel,
  ICarouselWithAsset,
  IContentChannel,
  IContentOverride,
  IOrganizationUnit,
  IProductOfferings,
  IRoom,
  IRoomType,
  ISettingsLanguage,
  IStreamChannel,
  ISubpage,
  ITemplate,
  PatchRequest,
  ResourcePermissions,
  Role,
} from "../../types/NendaTypes";
import { ApiTagType } from "../../types/RedBeeTypes";
import { ContentTimelineInterval } from "../components/CustomerPortal/store/reducers/screenReducer";
import { Asset } from "../models/asset";
import { OrganizationUnit } from "../models/organizationUnit";
import { INotification } from "../redux/notification.redux";

// --- SubPages ---
export enum SUBPAGES_ACTIONS {
  GET_SUBPAGES = "GET_SUBPAGES",
  SET_SUBPAGES = "SET_SUBPAGES",
  UPDATE_SUBPAGE = "UPDATE_SUBPAGE",
  CREATE_SUBPAGE = "CREATE_SUBPAGE",
  DELETE_SUBPAGE = "DELETE_SUBPAGE",
}

export interface GetSubPagesAction
  extends Action<SUBPAGES_ACTIONS.GET_SUBPAGES> {}

export interface SetSubPagesAction
  extends Action<SUBPAGES_ACTIONS.SET_SUBPAGES> {
  subPages: any;
}

export interface UpdateSubPageAction
  extends Action<SUBPAGES_ACTIONS.UPDATE_SUBPAGE> {
  payload: Partial<ISubpage>;
  id: string;
}

export interface CreateSubPageAction
  extends Action<SUBPAGES_ACTIONS.CREATE_SUBPAGE> {
  name: string;
}

export interface DeleteSubPageAction
  extends Action<SUBPAGES_ACTIONS.DELETE_SUBPAGE> {
  id: string;
}

export type SubPageActions =
  | GetSubPagesAction
  | SetSubPagesAction
  | UpdateSubPageAction
  | CreateSubPageAction
  | DeleteSubPageAction;

// --- Auth ---
export enum AUTH_ACTIONS {
  LOGIN = "LOGIN",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  SET_LOGIN = "SET_LOGIN",
  SET_LOGOUT = "SET_LOGOUT",
  GET_PERMISSIONS = "GET_PERMISSIONS",
  GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS",
  SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK",
  SEND_PASSWORD_RESET_LINK_SUCCESS = "SEND_PASSWORD_RESET_LINK_SUCCESS",
  RESET_PASSWORD = "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL",
}

export interface LoginFailureAction extends Action<AUTH_ACTIONS.LOGIN_FAILURE> {
  error: any;
}

export interface LoginAction extends Action<AUTH_ACTIONS.LOGIN> {
  username: string;
  password: string;
}

export interface SetLoginAction extends Action<AUTH_ACTIONS.SET_LOGIN> {
  session: any;
}

export interface SetLogoutAction extends Action<AUTH_ACTIONS.SET_LOGOUT> {}

export interface GetPermissionsAction
  extends Action<AUTH_ACTIONS.GET_PERMISSIONS> {}
export interface GetPermissionsSuccessAction
  extends Action<AUTH_ACTIONS.GET_PERMISSIONS_SUCCESS> {
  permissions: ResourcePermissions;
}

export interface SendPasswordResetLinkAction
  extends Action<AUTH_ACTIONS.SEND_PASSWORD_RESET_LINK> {
  email: string;
}

export interface SendPasswordResetLinkSuccessAction
  extends Action<AUTH_ACTIONS.SEND_PASSWORD_RESET_LINK_SUCCESS> {}

export interface ResetPasswordAction
  extends Action<AUTH_ACTIONS.RESET_PASSWORD> {
  hash?: string;
  newPassword: string;
}

export interface ResetPasswordSuccessAction
  extends Action<AUTH_ACTIONS.RESET_PASSWORD_SUCCESS> {}

export interface ResetPasswordFailAction
  extends Action<AUTH_ACTIONS.RESET_PASSWORD_FAIL> {
  error: any;
}

export type AuthActions =
  | LoginAction
  | LoginFailureAction
  | SetLoginAction
  | SetLogoutAction
  | GetPermissionsAction
  | GetPermissionsSuccessAction
  | SendPasswordResetLinkAction
  | SendPasswordResetLinkSuccessAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailAction;

// --- Tags ---
export enum TAG_ACTIONS {
  SET_TAGS = "SET_TAGS",
  GET_TAGS = "GET_TAGS",
}

export interface SetTagsAction extends Action<TAG_ACTIONS.SET_TAGS> {
  tags: any;
}

export interface GetTagsAction extends Action<TAG_ACTIONS.GET_TAGS> {}

export type TagsActions = SetTagsAction | GetTagsAction;

// --- Assets ---
export enum ASSET_ACTIONS {
  AUTOCOMPLETE = "AUTOCOMPLETE",
  SET_AUTOCOMPLETE = "SET_AUTOCOMPLETE",
  GET_ASSETS_FOR_CAROUSEL = "GET_ASSETS_FOR_CAROUSEL",
  SET_ASSETS_FOR_CAROUSEL = "SET_ASSETS_FOR_CAROUSEL",
}

export interface AutoCompleteAction extends Action<ASSET_ACTIONS.AUTOCOMPLETE> {
  query: any;
}
export interface SetAutocompleteAction
  extends Action<ASSET_ACTIONS.SET_AUTOCOMPLETE> {
  assets: any;
}
export interface GetAssetsForCarouselAction
  extends Action<ASSET_ACTIONS.GET_ASSETS_FOR_CAROUSEL> {
  query: string;
  sort: string;
  carouselId: string;
}
export interface SetAssetsForCarouselAction
  extends Action<ASSET_ACTIONS.SET_ASSETS_FOR_CAROUSEL> {
  assets: Array<Asset>;
  carouselId: string;
}

export type AssetActions =
  | AutoCompleteAction
  | SetAutocompleteAction
  | GetAssetsForCarouselAction
  | SetAssetsForCarouselAction;

// --- Carousels ---
export enum CAROUSEL_ACTIONS {
  SET_CAROUSELS = "SET_CAROUSELS",
  GET_CAROUSELS = "GET_CAROUSELS",
  UPDATE_CAROUSEL = "UPDATE_CAROUSEL",
  DELETE_CAROUSEL = "DELETE_CAROUSEL",
  CREATE_CAROUSEL = "CREATE_CAROUSEL",
}

export interface SetCaroueslsAction
  extends Action<CAROUSEL_ACTIONS.SET_CAROUSELS> {
  carousels: Array<ICarouselWithAsset | ICarousel>;
}
export interface UpdateCarouselAction
  extends Action<CAROUSEL_ACTIONS.UPDATE_CAROUSEL> {
  carouselId: string;
  payload: Partial<ICarouselWithAsset | ICarousel>;
}
export interface DeleteCarouselAction
  extends Action<CAROUSEL_ACTIONS.DELETE_CAROUSEL> {
  carouselId: string;
}
export interface GetCarouselsAction
  extends Action<CAROUSEL_ACTIONS.GET_CAROUSELS> {
  query: string;
}
export interface CreateCarouselAction
  extends Action<CAROUSEL_ACTIONS.CREATE_CAROUSEL> {
  payload: Partial<ICarouselWithAsset | ICarousel>;
}

export type CarouselsActions =
  | SetCaroueslsAction
  | UpdateCarouselAction
  | DeleteCarouselAction
  | GetCarouselsAction
  | CreateCarouselAction;

// --- Configs ---
export enum CONFIG_ACTIONS {
  GET_AVAILABLE_COUNTRIES = "GET_AVAILABLE_COUNTRIES",
  SET_AVAILABLE_COUNTRIES = "SET_AVAILABLE_COUNTRIES",
  GET_AVAILABLE_LANGUAGES = "GET_AVAILABLE_LANGUAGES",
  SET_AVAILABLE_LANGUAGES = "SET_AVAILABLE_LANGUAGES",
  GET_CONFIGS = "GET_CONFIGS",
  SET_CONFIGS = "SET_CONFIGS",
}

export interface GetCountriesAction
  extends Action<CONFIG_ACTIONS.GET_AVAILABLE_COUNTRIES> {}
export interface GetLanguagesAction
  extends Action<CONFIG_ACTIONS.GET_AVAILABLE_LANGUAGES> {}
export interface GetConfigsAction extends Action<CONFIG_ACTIONS.GET_CONFIGS> {}
export interface SetConfigsAction extends Action<CONFIG_ACTIONS.SET_CONFIGS> {
  configs: any;
}
export interface SetCountriesAction
  extends Action<CONFIG_ACTIONS.SET_AVAILABLE_COUNTRIES> {
  countryList: Array<string>;
}
export interface SetLanguagesAction
  extends Action<CONFIG_ACTIONS.SET_AVAILABLE_LANGUAGES> {
  languages: Array<ISettingsLanguage>;
}

export type ConfigActions =
  | GetCountriesAction
  | GetLanguagesAction
  | GetConfigsAction
  | SetConfigsAction
  | SetCountriesAction
  | SetLanguagesAction;

// --- Notifications ---

export enum NOTIFICATION_ACTIONS {
  SET_NOTIFICATION = "SET_NOTIFICATION",
}

export interface SetNotificationAction
  extends Action<NOTIFICATION_ACTIONS.SET_NOTIFICATION> {
  notification?: INotification;
}

export type NotificationsActions = SetNotificationAction;

// --- Utils ---
export enum UTILS_ACTIONS {
  CLEAR_CACHE = "CLEAR_CACHE",
}

export interface ClearCacheAction extends Action<UTILS_ACTIONS.CLEAR_CACHE> {
  cacheLevel: CacheLevel;
  id: string;
}

export type UtilsActions = ClearCacheAction;

// --- Error ---
export enum ERROR_ACTIONS {
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
}
export interface SetErrorAction extends Action<ERROR_ACTIONS.SET_ERROR> {
  currentError: any;
}
export interface SetLoadingAction extends Action<ERROR_ACTIONS.SET_LOADING> {
  loading: boolean;
}

export type ErrorActions = SetErrorAction | SetLoadingAction;

// --- Organization Unit ---
export enum ORGANIZATION_UNITS_ACTIONS {
  SET_ORGANIZATION_UNITS = "SET_ORGANIZATION_UNITS",
  GET_ORGANIZATION_UNITS = "GET_ORGANIZATION_UNITS",
  GET_CURRENT_PREMISE = "GET_CURRENT_PREMISE",
  SET_CURRENT_PREMISE = "SET_CURRENT_PREMISE",
  GET_PREMISES = "GET_PREMISES",
  SET_PREMISES = "SET_PREMISES",
  ADD_CAROUSEL_TO_ORGANIZATION_UNIT = "ADD_CAROUSEL_TO_ORGANIZATION_UNIT",
  REMOVE_CAROUSEL_FROM_ORGANIZATION_UNIT = "REMOVE_CAROUSEL_FROM_ORGANIZATION_UNIT",
  UPDATE_ORGANIZATION_UNIT = "UPDATE_ORGANIZATION_UNIT",
  UPDATE_ORGANIZATION_UNIT_SUCCESS = "UPDATE_ORGANIZATION_UNIT_SUCCESS",
  UPDATE_ORGANIZATION_UNIT_FAILURE = "UPDATE_ORGANIZATION_UNIT_FAILURE",
  UPDATE_ORGANIZATION_UNITS = "UPDATE_ORGANIZATION_UNITS",
  CREATE_ROOMS = "CREATE_ROOMS",
  DELETE_ORGANIZATION_UNIT = "DELETE_ORGANIZATION_UNIT",
  GET_ROOMS_FOR_HOTEL = "GET_ROOMS_FOR_HOTEL",
  SET_ROOMS_FOR_HOTEL = "SET_ROOMS_FOR_HOTEL",
  UPDATE_ROOM = "UPDATE_ROOM",
  DELETE_ROOM = "DELETE_ROOM",
  CREATE_AREA = "CREATE_AREA",
  CREATE_ROOM_TYPE = "CREATE_ROOM_TYPE",
  UPDATE_ROOM_TYPE = "UPDATE_ROOM_TYPE",
  DELETE_ROOM_TYPE = "DELETE_ROOM_TYPE",
  RESYNC_ROOM_TYPE = "RESYNC_ROOM_TYPE",
  SET_RESYNC_ROOM_TYPE_DATA = "SET_RESYNC_ROOM_TYPE_DATA",
  UPDATE_ROOM_TYPE_ALL_ROOMS = "UPDATE_ROOM_TYPE_ALL_ROOMS",
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE",
  SET_PREMISE = "SET_PREMISE",
  CREATE_ORGANIZATION_UNIT = "CREATE_ORGANIZATION_UNIT",
  GET_CONTENT_PACKAGES_BY_PREMISE = "GET_CONTENT_PACKAGES_BY_PREMISE",
  GET_CONTENT_PACKAGES_BY_PREMISE_FAILURE = "GET_CONTENT_PACKAGES_BY_PREMISE_FAILURE",
  SET_CONTENT_PACKAGES_BY_PREMISE = "SET_CONTENT_PACKAGES_BY_PREMISE",
  SEND_CONTENT_ORDER = "SEND_CONTENT_ORDER",
  SEND_CONTENT_ORDER_SUCCESS = "SEND_CONTENT_ORDER_SUCCESS",
  SEND_CONTENT_ORDER_FAILURE = "SEND_CONTENT_ORDER_FAILURE",
}

export interface SetOrganizationUnitsAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.SET_ORGANIZATION_UNITS> {
  organizationUnits: Array<OrganizationUnit>;
}
export interface GetPremisesAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.GET_PREMISES> {
  payload?: any;
}
export interface SetPremisesAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.SET_PREMISES> {
  payload?: any;
}
export interface SetPremiseAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.SET_PREMISE> {
  payload?: any;
}
export interface GetOrganizationUnitsAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.GET_ORGANIZATION_UNITS> {}
export interface CreateOrganizationUnitAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.CREATE_ORGANIZATION_UNIT> {
  payload: Partial<OrganizationUnit>;
  notifyEmailAddress?: string;
}
export interface AddCarouselToOrganizationUnitAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.ADD_CAROUSEL_TO_ORGANIZATION_UNIT> {
  organizationUnitId: string;
  carouselId: string;
}
export interface RemoveCarouselFromOrganizationUnitAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.REMOVE_CAROUSEL_FROM_ORGANIZATION_UNIT> {
  organizationUnitId: string;
  carouselId: string;
}
export interface UpdateOrganizationUnitAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT> {
  organizationUnitId: string;
  payload: Partial<IOrganizationUnit>;
}

export interface UpdateOrganizationUnitSuccessAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT_SUCCESS> {
  payload: OrganizationUnit;
}

export interface UpdateOrganizationUnitFailureAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT_FAILURE> {
  error: any;
}

export interface UpdateOrganizationUnitAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNIT> {
  organizationUnitId: string;
  payload: Partial<IOrganizationUnit>;
}

export interface UpdateOrganizationUnitsAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ORGANIZATION_UNITS> {
  organizationUnitIds: Array<string>;
  payload: Partial<OrganizationUnit>;
}
export interface CreateRoomsAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.CREATE_ROOMS> {
  payload: any;
}
export interface CreateAreaAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.CREATE_AREA> {
  payload: any;
}
export interface CreateRoomTypeAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.CREATE_ROOM_TYPE> {
  organizationUnitId: string;
  payload: Partial<IRoomType>;
}
export interface UpdateRoomTypeAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM_TYPE> {
  organizationUnitId: string;
  roomTypeId: string;
  payload: Partial<IRoomType>;
}
export interface DeleteRoomTypeAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.DELETE_ROOM_TYPE> {
  organizationUnitId: string;
  roomTypeId: string;
}
export interface ResyncRoomTypeAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.RESYNC_ROOM_TYPE> {
  organizationUnitId: any;
  roomTypeId: any;
}

export interface SetResyncRoomTypeAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.SET_RESYNC_ROOM_TYPE_DATA> {
  roomTypeId: any;
  data: any;
}

export interface UpdateRoomTypeAllRoomsAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM_TYPE_ALL_ROOMS> {
  organizationUnitId: string;
  roomTypeId: string;
}

export interface DeleteOrganizationUnitAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.DELETE_ORGANIZATION_UNIT> {
  id: string;
}
export interface GetRoomsForHotelAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.GET_ROOMS_FOR_HOTEL> {
  hotelId: string;
}
export interface SetRoomsForHotelAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.SET_ROOMS_FOR_HOTEL> {
  hotelId: string;
  rooms: Array<IRoom>;
}
export interface UpdateRoomAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM> {
  roomId: string;
  room: Partial<IRoom>;
}
export interface DeleteRoomAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.DELETE_ROOM> {
  roomId: string;
}

export interface UploadImageAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPLOAD_IMAGE> {
  organizationUnitId: string;
  image: any;
  scope: string;
  assetType: string;
}

export interface UploadImageSuccessAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPLOAD_IMAGE_SUCCESS> {
  organizationUnitId: string;
  response: any;
  scope: string;
  assetType: string;
}

export interface UploadImageFailureAction
  extends Action<ORGANIZATION_UNITS_ACTIONS.UPLOAD_IMAGE_FAILURE> {
  error: any;
}

export type OrganizationUnitActions =
  | SetOrganizationUnitsAction
  | GetOrganizationUnitsAction
  | CreateOrganizationUnitAction
  | AddCarouselToOrganizationUnitAction
  | RemoveCarouselFromOrganizationUnitAction
  | UpdateOrganizationUnitAction
  | UpdateOrganizationUnitSuccessAction
  | UpdateOrganizationUnitFailureAction
  | UpdateOrganizationUnitsAction
  | CreateRoomsAction
  | CreateAreaAction
  | CreateRoomTypeAction
  | UpdateRoomTypeAction
  | DeleteRoomTypeAction
  | DeleteOrganizationUnitAction
  | GetRoomsForHotelAction
  | SetRoomsForHotelAction
  | UpdateRoomAction
  | DeleteRoomAction
  | ResyncRoomTypeAction
  | SetResyncRoomTypeAction
  | UploadImageAction
  | UploadImageSuccessAction
  | UploadImageFailureAction;

// --- Product Offerings ---
export enum PRODUCT_OFFERING_ACTIONS {
  GET_PRODUCT_OFFERINGS = "GET_PRODUCT_OFFERINGS",
  SET_PRODUCT_OFFERINGS = "SET_PRODUCT_OFFERINGS",
  SET_PRODUCT_OFFERING = "SET_PRODUCT_OFFERING",
  EDIT_PRODUCT_OFFERING = "EDIT_PRODUCT_OFFERING",
  GET_PRODUCT_OFFERING_BY_ID = "GET_PRODUCT_OFFERING_BY_ID",
}

export interface EditProductOfferingAction
  extends Action<PRODUCT_OFFERING_ACTIONS.EDIT_PRODUCT_OFFERING> {
  hotelChainId: string;
  productOfferingId: string;
  labels: any;
}
export interface SetProductOfferingAction
  extends Action<PRODUCT_OFFERING_ACTIONS.SET_PRODUCT_OFFERING> {
  productOffering: IProductOfferings;
}
export interface GetProductOfferingsAction
  extends Action<PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERINGS> {
  id: string | Array<string>;
}
export interface GetProductOfferingByIdAction
  extends Action<PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERING_BY_ID> {
  hotelChainId: string;
  productOfferingId: string;
}
export interface SetProductOfferingsAction
  extends Action<PRODUCT_OFFERING_ACTIONS.SET_PRODUCT_OFFERINGS> {
  productOfferings: Array<IProductOfferings>;
}

export type ProductOfferingsActions =
  | EditProductOfferingAction
  | SetProductOfferingAction
  | GetProductOfferingsAction
  | GetProductOfferingByIdAction
  | SetProductOfferingsAction;

// --- Templates ---
export enum TEMPLATES_ACTIONS {
  SET_TEMPLATES = "SET_TEMPLATES",
  GET_TEMPLATES = "GET_TEMPLATES",
  CREATE_TEMPLATE = "CREATE_TEMPLATE",
  DELETE_TEMPLATE = "DELETE_TEMPLATE",
  UPDATE_TEMPLATE = "UPDATE_TEMPLATE",
}

export interface SetTemplatesAction
  extends Action<TEMPLATES_ACTIONS.SET_TEMPLATES> {
  templates: Array<ITemplate>;
}

export interface GetTemplatesAction
  extends Action<TEMPLATES_ACTIONS.GET_TEMPLATES> {}
export interface CreateTemplatesAction
  extends Action<TEMPLATES_ACTIONS.CREATE_TEMPLATE> {
  name: string;
}
export interface DeleteTemplateAction
  extends Action<TEMPLATES_ACTIONS.DELETE_TEMPLATE> {
  id: string;
}
export interface UpdateTemplateAction
  extends Action<TEMPLATES_ACTIONS.UPDATE_TEMPLATE> {
  id: string;
  data: Partial<ITemplate>;
}

export type TemplatesActions =
  | SetTemplatesAction
  | GetTemplatesAction
  | CreateTemplatesAction
  | DeleteTemplateAction
  | UpdateTemplateAction;

// --- Customization ---

export enum CUSTOMOMIZATION_ACTIONS {
  SET_EDIT_PROMOTION_ID = "SET_EDIT_PROMOTION_ID",
  SETTINGS_TOGGLE = "SETTINGS_TOGGLE",
  SET_MENU = "SET_MENU",
  MENU_TOGGLE = "MENU_TOGGLE",
  MENU_OPEN = "MENU_OPEN",
  TOGGLE_PROMOTION_MODAL = "TOGGLE_PROMOTION_SCREEN",
  TOGGLE_CONTENT_OVERRIDE_MODAL = "TOGGLE_CONTENT_OVERRIDE_MODAL",
}

export interface SetMenuAction
  extends Action<CUSTOMOMIZATION_ACTIONS.SET_MENU> {
  opened: boolean;
}

export interface ToggleMenuAction
  extends Action<CUSTOMOMIZATION_ACTIONS.MENU_TOGGLE> {}
export interface SetSettingsAction
  extends Action<CUSTOMOMIZATION_ACTIONS.SETTINGS_TOGGLE> {
  opened: boolean;
}

export interface MenuOpenAction
  extends Action<CUSTOMOMIZATION_ACTIONS.MENU_OPEN> {
  id: string;
}

export type CustomizationActions =
  | SetMenuAction
  | MenuOpenAction
  | SetSettingsAction
  | ToggleBookingScreenAction
  | SetEditBookingIdAction
  | SetEffectPremiseTagFilterAction
  | SetEffectScreenTagFilterAction
  | ToggleMenuAction;

// --- SCREEN ---

export enum SCREEN_ACTIONS {
  GET_SCREENS = "GET_SCREENS",
  GET_SCREENS_FAILURE = "GET_SCREENS_FAILURE",
  GET_SCREENS_SUCCESS = "GET_SCREENS_SUCCESS",
  GET_TIMELINE_FOR_SCREEN = "GET_TIMELINE_FOR_SCREEN",
  GET_TIMELINE_FOR_SCREEN_SUCCESS = "GET_TIMELINE_FOR_SCREEN_SUCCESS",
  BATCH_GET_TIMELINE_FOR_SCREEN = "BATCH_GET_TIMELINE_FOR_SCREEN",
  BATCH_GET_TIMELINE_FOR_SCREEN_SUCCESS = "BATCH_GET_TIMELINE_FOR_SCREEN_SUCCESS",
  BATCH_GET_SCREENS = "BATCH_GET_SCREENS",
  BATCH_GET_SCREENS_SUCCESS = "BATCH_GET_SCREENS_SUCCESS",
  BATCH_GET_SCREENS_FAILURE = "BATCH_GET_SCREENS_FAILURE",
  UPDATE_SCREEN = "UPDATE_SCREEN",
  UPDATE_SCREEN_SUCCESS = "UPDATE_SCREEN_SUCCESS",
  UPDATE_SCREEN_FAILURE = "UPDATE_SCREEN_FAILURE",
  UPDATE_CONTENT_OVERRIDE = "UPDATE_CONTENT_OVERRIDE",
}

export interface GetScreensAction extends Action<SCREEN_ACTIONS.GET_SCREENS> {
  premiseId: string;
}

export interface GetTimelineForScreenAction
  extends Action<SCREEN_ACTIONS.GET_TIMELINE_FOR_SCREEN> {
  id: string;
  query: {
    from: string;
    to: string;
    type?: string;
  };
}

export interface BatchGetTimelineForScreenAction {
  ids: string[];
  query: {
    from: string;
    to: string;
  };
}

export interface BatchGetTimelineForScreenSuccessAction {
  timelines: Record<string, ContentTimelineInterval[]>;
}

export interface GetScreensSuccessAction
  extends Action<SCREEN_ACTIONS.GET_SCREENS_SUCCESS> {
  premiseId: string;
  screens: Array<IRoom>;
}

export interface GetScreensFailureAction
  extends Action<SCREEN_ACTIONS.GET_SCREENS_FAILURE> {
  error: any;
}

export interface BatchGetScreensAction
  extends Action<SCREEN_ACTIONS.BATCH_GET_SCREENS> {
  premiseIds: string[];
}

export interface BatchGetScreensSuccessAction
  extends Action<SCREEN_ACTIONS.BATCH_GET_SCREENS_SUCCESS> {
  premiseIds: string[];
  screens: Array<IRoom>;
}

export interface GetTimelineForScreenSuccessAction
  extends Action<SCREEN_ACTIONS.GET_TIMELINE_FOR_SCREEN_SUCCESS> {
  ids: string[];
  timelines: Timeline[];
}

export interface BatchGetScreensFailureAction
  extends Action<SCREEN_ACTIONS.BATCH_GET_SCREENS_FAILURE> {
  error: any;
}

export interface SetEffectPremiseTagFilterAction
  extends Action<CUSTOMOMIZATION_ACTIONS.SET_EFFECT_PREMISE_TAG_FILTER> {
  tags: string;
}
export interface SetEffectScreenTagFilterAction
  extends Action<CUSTOMOMIZATION_ACTIONS.SET_EFFECT_SCREEN_TAG_FILTER> {
  tags: string;
}

export interface SetEditBookingIdAction
  extends Action<CUSTOMOMIZATION_ACTIONS.SET_EDIT_BOOKING_ID> {
  bookingId: string;
}

export interface UpdateScreenAction
  extends Action<SCREEN_ACTIONS.UPDATE_SCREEN> {
  screenId: string;
  data: Partial<IRoom>;
}

export interface UpdateContentOverrideAction
  extends Action<SCREEN_ACTIONS.UPDATE_CONTENT_OVERRIDE> {
  screenId: string;
  data: IContentOverride;
}

export interface UpdateScreenActionSuccess
  extends Action<SCREEN_ACTIONS.UPDATE_SCREEN_SUCCESS> {
  payload: IRoom;
}

export interface UpdateScreenActionFailure
  extends Action<SCREEN_ACTIONS.UPDATE_SCREEN_FAILURE> {
  error: any;
}

export type ScreenActions =
  | GetScreensAction
  | GetScreensSuccessAction
  | GetScreensFailureAction
  | BatchGetScreensAction
  | BatchGetScreensSuccessAction
  | BatchGetScreensFailureAction
  | UpdateScreenAction
  | UpdateScreenActionSuccess
  | UpdateScreenActionSuccess
  | GetTimelineForScreenAction
  | BatchGetTimelineForScreenAction
  | GetTimelineForScreenSuccessAction
  | BatchGetTimelineForScreenSuccessAction;

// --- Content Channel ---

export enum CONTENTCHANNEL_ACTIONS {
  GET_CONTENTCHANNEL = "GET_CONTENTCHANNEL",
  GET_CONTENTCHANNEL_FAILURE = "GET_CONTENTCHANNEL_FAILURE",
  GET_CONTENTCHANNEL_SUCCESS = "GET_CONTENTCHANNEL_SUCCESS",
  GET_CONTENTCHANNELS = "GET_CONTENTCHANNELS",
  GET_CONTENTCHANNELS_FAILURE = "GET_CONTENTCHANNELS_FAILURE",
  GET_CONTENTCHANNELS_SUCCESS = "GET_CONTENTCHANNELS_SUCCESS",
  ADD_CONTENTCHANNEL = "ADD_CONTENTCHANNEL",
  ADD_CONTENTCHANNEL_FAILURE = "ADD_CONTENTCHANNEL_FAILURE",
  ADD_CONTENTCHANNEL_SUCCESS = "ADD_CONTENTCHANNEL_SUCCESS",
  DELETE_CONTENTCHANNEL = "DELETE_CONTENTCHANNEL",
  DELETE_CONTENTCHANNEL_FAILURE = "DELETE_CONTENTCHANNEL_FAILURE",
  DELETE_CONTENTCHANNEL_SUCCESS = "DELETE_CONTENTCHANNEL_SUCCESS",
  UPDATE_CONTENTCHANNEL_SUCCESS = "UPDATE_CONTENTCHANNEL_SUCCESS",
  UPDATE_CONTENTCHANNEL = "UPDATE_CONTENTCHANNEL",
  UPDATE_CONTENTCHANNEL_FAILURE = "UPDATE_CONTENTCHANNEL_FAILURE",
  FILE_UPLOAD = "FILE_UPLOAD",
  UPLOAD_LOGO = "UPLOAD_LOGO",
  UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS",
  UPLOAD_POSTER_SUCCESS = "UPLOAD_POSTER_SUCCESS",
  CLEAR_FILE_UPLOADS = "CLEAR_FILE_UPLOADS",
  UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE",
}
export interface GetContentChannelAction
  extends Action<CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNEL> {
  payload: any;
}

export interface GetContentChannelSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNEL_SUCCESS> {
  channel: IContentChannel;
}

export interface GetContentChannelFailureAction
  extends Action<CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNEL_FAILURE> {
  error: any;
}
export interface GetContentChannelsAction
  extends Action<CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS> {}

export interface GetContentChannelsSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS_SUCCESS> {
  channels: IContentChannel[];
}

export interface GetContentChannelsFailureAction
  extends Action<CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS_FAILURE> {
  error: any;
}

export interface AddContentChannelAction
  extends Action<CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL> {
  data: IContentChannel;
}

export interface AddContentChannelSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL_SUCCESS> {
  channel: IContentChannel;
}

export interface AddContentChannelFailureAction
  extends Action<CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL_FAILURE> {
  error: any;
}

export interface UpdateContentChannelAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL> {
  request: PatchRequest<IContentChannel>;
}

export interface UpdateContentChannelSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL_SUCCESS> {
  channel: IContentChannel;
}

export interface UpdateContentChannelFailureAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL_FAILURE> {
  error: any;
}

export interface DeleteContentChannelAction
  extends Action<CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL> {
  channelId: string;
}

export interface DeleteContentChannelSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL_SUCCESS> {
  channel: IContentChannel;
}

export interface DeleteContentChannelFailureAction
  extends Action<CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL_FAILURE> {
  error: any;
}

export interface UploadFileFailureAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPLOAD_FILE_FAILURE> {
  error: any;
}

export interface UploadFileAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO> {
  channelId: string;
  image: File;
  scope: FileUploadScope;
}

export interface ClearFileUploadAction
  extends Action<CONTENTCHANNEL_ACTIONS.CLEAR_FILE_UPLOADS> {
  scope: FileUploadScope;
}

export interface UploadLogoSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO_SUCCESS> {
  channelId: string;
  uploadImageResponse: FileUploadResponse;
}
export interface UploadPosterSuccessAction
  extends Action<CONTENTCHANNEL_ACTIONS.UPLOAD_POSTER_SUCCESS> {
  channelId: string;
  uploadImageResponse: FileUploadResponse;
}

export type ContentChannelActions =
  | GetContentChannelsAction
  | GetContentChannelsSuccessAction
  | GetContentChannelsFailureAction
  | AddContentChannelAction
  | AddContentChannelSuccessAction
  | AddContentChannelFailureAction
  | UpdateContentChannelAction
  | UpdateContentChannelSuccessAction
  | UpdateContentChannelFailureAction
  | DeleteContentChannelAction
  | DeleteContentChannelSuccessAction
  | DeleteContentChannelFailureAction
  | UploadFileAction
  | UploadLogoSuccessAction
  | UploadPosterSuccessAction
  | UploadFileFailureAction
  | ClearFileUploadAction;

// --- Stream Channel ---

export enum STREAMCHANNEL_ACTIONS {
  GET_STREAMCHANNEL = "GET_STREAMCHANNEL",
  GET_STREAMCHANNEL_FAILURE = "GET_STREAMCHANNEL_FAILURE",
  GET_STREAMCHANNEL_SUCCESS = "GET_STREAMCHANNEL_SUCCESS",
  GET_STREAMCHANNELS = "GET_STREAMCHANNELS",
  GET_STREAMCHANNELS_FAILURE = "GET_STREAMCHANNELS_FAILURE",
  GET_STREAMCHANNELS_SUCCESS = "GET_STREAMCHANNELS_SUCCESS",
  CREATE_STREAMCHANNEL = "CREATE_STREAMCHANNEL",
  CREATE_STREAMCHANNEL_FAILURE = "CREATE_STREAMCHANNEL_FAILURE",
  CREATE_STREAMCHANNEL_SUCCESS = "CREATE_STREAMCHANNEL_SUCCESS",
  UPDATE_STREAMCHANNEL = "UPDATE_STREAMCHANNEL",
  UPDATE_STREAMCHANNEL_FAILURE = "UPDATE_STREAMCHANNEL_FAILURE",
  UPDATE_STREAMCHANNEL_SUCCESS = "UPDATE_STREAMCHANNEL_SUCCESS",
  DELETE_STREAMCHANNEL = "DELETE_STREAMCHANNEL",
  DELETE_STREAMCHANNEL_FAILURE = "DELETE_STREAMCHANNEL_FAILURE",
  DELETE_STREAMCHANNEL_SUCCESS = "DELETE_STREAMCHANNEL_SUCCESS",
}

export interface GetStreamChannelAction
  extends Action<STREAMCHANNEL_ACTIONS.GET_STREAMCHANNEL> {
  id: string;
}

export interface GetStreamChannelSuccessAction
  extends Action<STREAMCHANNEL_ACTIONS.GET_STREAMCHANNEL_SUCCESS> {
  channel: IStreamChannel;
}

export interface GetStreamChannelFailureAction
  extends Action<STREAMCHANNEL_ACTIONS.GET_STREAMCHANNEL_FAILURE> {
  error: any;
}

export interface GetStreamChannelsAction
  extends Action<STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS> {}

export interface GetStreamChannelsSuccessAction
  extends Action<STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS_SUCCESS> {
  channels: IStreamChannel[];
}

export interface GetStreamChannelsFailureAction
  extends Action<STREAMCHANNEL_ACTIONS.GET_STREAMCHANNELS_FAILURE> {
  error: any;
}

export interface CreateStreamChannelAction
  extends Action<STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL> {
  channel: IStreamChannel;
}

export interface CreateStreamChannelSuccessAction
  extends Action<STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL_SUCCESS> {
  channel: IStreamChannel;
}

export interface CreateStreamChannelFailureAction
  extends Action<STREAMCHANNEL_ACTIONS.CREATE_STREAMCHANNEL_FAILURE> {
  error: any;
}

export interface UpdateStreamChannelAction
  extends Action<STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL> {
  channelId: string;
  data: Partial<IStreamChannel>;
}

export interface UpdateStreamChannelSuccessAction
  extends Action<STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL_SUCCESS> {
  channel: IStreamChannel;
}

export interface UpdateStreamChannelFailureAction
  extends Action<STREAMCHANNEL_ACTIONS.UPDATE_STREAMCHANNEL_FAILURE> {
  error: any;
}

export interface DeleteStreamChannelAction
  extends Action<STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL> {
  channelId: string;
}

export interface DeleteStreamChannelSuccessAction
  extends Action<STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL_SUCCESS> {
  channelId: string;
}

export interface DeleteStreamChannelFailureAction
  extends Action<STREAMCHANNEL_ACTIONS.DELETE_STREAMCHANNEL_FAILURE> {
  error: any;
}

export type StreamChannelActions =
  | GetStreamChannelAction
  | GetStreamChannelSuccessAction
  | GetStreamChannelFailureAction
  | GetStreamChannelsAction
  | GetStreamChannelsSuccessAction
  | GetStreamChannelsFailureAction
  | CreateStreamChannelAction
  | CreateStreamChannelSuccessAction
  | CreateStreamChannelFailureAction
  | UpdateStreamChannelAction
  | UpdateStreamChannelSuccessAction
  | UpdateStreamChannelFailureAction
  | DeleteStreamChannelAction
  | DeleteStreamChannelSuccessAction
  | DeleteStreamChannelFailureAction;

// --- Upsales ---

export enum UPSALES_ACTIONS {
  GET_COMPANIES = "GET_COMPANIES",
  GET_COMPANY = "GET_COMPANY",
  GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS",
  GET_SUBSCRIPTION = "GET_SUBSCRIPTION",
  GET_ORDERS = "GET_ORDERS",
  GET_ORDER = "GET_ORDER",
  SET_COMPANIES = "SET_COMPANIES",
  SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS",
  SET_ORDERS = "SET_ORDERS",
}

export interface GetCompaniesAction
  extends Action<UPSALES_ACTIONS.GET_COMPANIES> {}

export interface SetCompaniesAction
  extends Action<UPSALES_ACTIONS.SET_COMPANIES> {
  companies: any;
}
export interface GetCompanyAction extends Action<UPSALES_ACTIONS.GET_COMPANY> {
  id: number;
}

export interface GetSubscriptionsAction
  extends Action<UPSALES_ACTIONS.GET_SUBSCRIPTIONS> {}

export interface SetSubscriptionsAction
  extends Action<UPSALES_ACTIONS.SET_SUBSCRIPTIONS> {
  subscriptions: any;
}
export interface GetSubscriptionAction
  extends Action<UPSALES_ACTIONS.GET_SUBSCRIPTION> {
  id: number;
}

export interface GetOrdersAction extends Action<UPSALES_ACTIONS.GET_ORDERS> {}

export interface SetOrdersAction extends Action<UPSALES_ACTIONS.SET_ORDERS> {
  orders: any;
}

export interface GetOrderAction extends Action<UPSALES_ACTIONS.GET_ORDER> {
  id: number;
}

export type UpsalesActions =
  | GetCompaniesAction
  | SetCompaniesAction
  | GetCompanyAction
  | GetSubscriptionsAction
  | SetSubscriptionsAction
  | GetSubscriptionAction
  | GetOrdersAction
  | SetOrdersAction
  | GetOrderAction;

// --- State ---
export interface AppState {
  loading: boolean;
  carousels: {
    carousels: Array<ICarouselWithAsset | ICarousel>;
    loading: boolean;
  };
  error?: {
    currentError: any;
    loading?: boolean;
  };
  organizationUnits: {
    organizationUnits: Array<OrganizationUnit>;
    hotelRooms: Array<{
      hotelId: string;
      rooms: Array<IRoom>;
    }>;
    loading: boolean;
    resync?: any;
  };
  productOfferings: {
    productOfferings: Array<IProductOfferings>;
    loading: boolean;
    productOffering: IProductOfferings | any;
  };
  tags: {
    tags: Array<ApiTagType>;
  };
  auth: {
    session?: {
      sessionToken: string;
      type: Role;
      expiration: string;
    };
  };
  routing: any;
  config: {
    languages: Array<ISettingsLanguage>;
    availableCountries: Array<string>;
    roomTypeCategories: Array<string>;
  };
  subPages: {
    subPages: Array<ISubpage>;
  };
  assets: {
    autocomplete: Array<any>;
    assetsPerCarousel: { carouselId: string; assets: Array<Asset> }[];
  };
  notification: {
    currentNotification?: INotification;
  };
  templates: Array<ITemplate>;
  upsales: {
    companies: CompanyData[];
    subscriptions: SubscriptionData[];
    orders: OrderData[];
  };
}
