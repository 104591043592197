import { Box, Button, Fade, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import {
  IContentChannel,
  NendaProduct,
  ScheduleContentRefType,
} from "../../../../types/NendaTypes";

import { ArrowBack, ArrowForward, Image } from "@mui/icons-material";
import DisabledFeatures from "../components/Content/components/DisabledFeatures";
import PlayContentModal from "../components/Content/components/PlayContentModal";
import { isContentChannel } from "../components/DailyPlaylist/PlaylistTimeline";
import { CustomerPortalState, store } from "../store";
import { selectCategorizedContentChannelsByNavigatedPremise } from "../store/reducers/contentChannelReducer";
import { SetContentOverrideModal } from "../store/reducers/customizationReducer";
import {
  getScreens,
  selectScreensByProductAndPremise,
} from "../store/reducers/screenReducer";
import { selectNavigationScope } from "../store/reducers/workspaceReducer";

const Channels = () => {
  const scope = useSelector(selectNavigationScope);

  const screens = useSelector((state: CustomerPortalState) =>
    selectScreensByProductAndPremise(
      state,
      NendaProduct.SIGNAGE,
      scope.premise || ""
    )
  );

  const channelsByCategory = useSelector(
    selectCategorizedContentChannelsByNavigatedPremise
  );

  useEffect(() => {
    if (scope.premise) {
      store.dispatch(getScreens(scope.premise || ""));
    }
  }, [scope.premise]);

  const handleContentOverrideModalClick = (
    id: string,
    contentRefType: ScheduleContentRefType
  ) => {
    store.dispatch(SetContentOverrideModal(id, contentRefType));
  };

  const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          background: "rgba(255,255,255,0.7)",
          border: "none",
          cursor: "pointer",
          padding: "0.5rem",
          borderRadius: "50%",
          "&:hover": {
            background: "rgba(255,255,255,1)",
          },
        }}
      >
        <ArrowForward onClick={onClick} sx={{ color: "black" }} />
      </Box>
    );
  };
  const CustomLeftArrow = ({ onClick }: { onClick?: () => void }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          background: "rgba(255,255,255,0.7)",
          border: "none",
          cursor: "pointer",
          padding: "0.5rem",
          borderRadius: "50%",
          "&:hover": {
            background: "rgba(255,255,255,1)",
          },
        }}
      >
        <ArrowBack onClick={onClick} sx={{ color: "black" }} />
      </Box>
    );
  };

  const channelBackground = (channel: IContentChannel) => {
    if (channel.posterUrl) {
      return (
        <Box
          sx={(theme) => ({
            height: "100%",
            width: "100%",
            backgroundImage: `url(${channel.posterUrl})`,
            backgroundColor: theme.palette.primary.dark,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          })}
        />
      );
    } else {
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 1rem 3rem 1rem",
            background: "linear-gradient(0deg, #dad7f4 37%, #E4E2F4 100%)",
          }}
        >
          <Box
            sx={{
              height: "130px",
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
              backgroundColor: "rgba(255,255,255,0.4)",
              borderRadius: "5px",
              boxShadow: "0px 10px 15px 2px rgba(0,0,0,0.1)",
            }}
          >
            {channel.logo?.locator ? (
              <img
                src={channel.logo.locator}
                style={{
                  borderRadius: "5px",
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                }}
              />
            ) : (
              <Image
                sx={{
                  color: "rgba(0,0,0,0.2)",
                  fontSize: "4rem",
                }}
              />
            )}
          </Box>
        </Box>
      );
    }
  };

  return (
    <Fade in={true}>
      <Box>
        <PlayContentModal screens={screens} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            mb: "1rem",
          }}
        >
          <Typography
            variant="h3"
            sx={(theme) => ({
              textTransform: "capitalize",
              color: theme.palette.text.primary,
            })}
          >
            {t("customerportal.pages.content_channels.title")}
          </Typography>
          <Typography
            sx={{ paddingBottom: "1rem", maxWidth: "40rem" }}
            variant="body1"
          >
            {t("customerportal.pages.content_channels.helper_text")}
          </Typography>
        </Box>
        {Object.keys(channelsByCategory).map((category) => (
          <Box sx={{ paddingBottom: "1rem" }} key={category}>
            <Typography
              sx={{ paddingBottom: "1rem" }}
              variant="h6"
              fontSize={"1.4rem"}
            >
              {category}
            </Typography>
            <Carousel
              infinite={false}
              rewind={true}
              rewindWithAnimation={true}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 3000, min: 2001 },
                  items: 6.5,
                  slidesToSlide: 6,
                },
                prettyLargeDesktop: {
                  breakpoint: { max: 2000, min: 1560 },
                  items: 5.5,
                  slidesToSlide: 5,
                },
                desktop: {
                  breakpoint: { max: 1560, min: 1024 },
                  items: 4.5,
                  slidesToSlide: 4,
                  paritialVisibilityGutter: 40,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 700 },
                  items: 3,
                },
                mobile: {
                  breakpoint: { max: 700, min: 0 },
                  items: 2,
                },
              }}
            >
              {channelsByCategory[category].map((channel: IContentChannel) => {
                return (
                  <Box
                    key={channel._id}
                    sx={{
                      height: "323.2px", //Calculated from the size of poster images used
                      width: "212px",
                      borderRadius: "0.3rem",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover .overlay": {
                        opacity: 1,
                      },
                    }}
                  >
                    {isContentChannel(channel) && (
                      <DisabledFeatures
                        permissions={channel.adPermissions}
                        sx={{
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1,
                          top: 0,
                          right: 0,
                          backgroundColor: "white",
                          p: "0.2rem",
                        }}
                      />
                    )}
                    {channelBackground(channel)}
                    <Box
                      className="overlay"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        transition: "opacity 0.3s",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                        justifyContent: "center",
                        px: "0.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          background: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "0.2rem",
                          py: "0.2rem",
                          "&:hover": {
                            background: "rgba(255,255,255,0.9)",
                          },
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleContentOverrideModalClick(
                              channel._id,
                              ScheduleContentRefType.CONTENT_CHANNEL
                            )
                          }
                          variant="text"
                          sx={{ width: "100%" }}
                        >
                          {t("common.play_now")}
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        textAlign: "center",
                        py: "0.5rem",
                        px: "0.2rem",
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        color: "white",
                      }}
                    >
                      <Typography variant="button">{channel.name}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        ))}
      </Box>
    </Fade>
  );
};

export default Channels;
